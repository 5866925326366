import type { Components, Theme } from '@mui/material/styles';

import type { SettingsState } from '../../components/settings/types';
import COLORS from '../core/colors.json';
import { card as MuiCardCoreComponent } from '../core/components/card';
import { customShadows as coreCustomShadows, createShadowColor } from '../core/custom-shadows';
import { grey as coreGreyPalette, primary as corePrimaryPalette } from '../core/palette';
import { createPaletteChannel, hexToRgbChannel } from '../styles/utils';
import type { ThemeComponents, ThemeUpdateOptions } from '../types';
import PRIMARY_COLOR from './primary-color.json';

const PRIMARY_COLORS = {
    default: COLORS.primary,
    cyan: PRIMARY_COLOR.cyan,
    purple: PRIMARY_COLOR.purple,
    blue: PRIMARY_COLOR.blue,
    orange: PRIMARY_COLOR.orange,
    red: PRIMARY_COLOR.red,
};

/**
 * Get the primary palette based on the settings.
 * @param primaryColorName - The primary color name.
 */
function getPalettePrimary(primaryColorName: SettingsState['primaryColor']) {
    /** [1] */
    const selectedPrimaryColor = PRIMARY_COLORS[primaryColorName];
    const updatedPrimaryPalette = createPaletteChannel(selectedPrimaryColor);

    return primaryColorName === 'default' ? corePrimaryPalette : updatedPrimaryPalette;
}

/**
 * Get the default background color based on the contrast settings.
 * @param contrast - The contrast settings.
 */
function getBackgroundDefault(contrast: SettingsState['contrast']) {
    /** [2] */
    return contrast === 'default' ? '#FFFFFF' : coreGreyPalette[200];
}

/**
 * [1] settings @primaryColor
 * [2] settings @contrast
 */
export function updateCoreWithSettings(
    theme: ThemeUpdateOptions,
    settings: SettingsState,
): ThemeUpdateOptions {
    const { colorSchemes, customShadows } = theme;

    return {
        ...theme,
        colorSchemes: {
            ...colorSchemes,
            light: {
                palette: {
                    ...colorSchemes?.light?.palette,
                    /** [1] */
                    primary: getPalettePrimary(settings.primaryColor),
                    /** [2] */
                    background: {
                        ...colorSchemes?.light?.palette?.background,
                        default: getBackgroundDefault(settings.contrast),
                        defaultChannel: hexToRgbChannel(getBackgroundDefault(settings.contrast)),
                    },
                },
            },
            dark: {
                palette: {
                    ...colorSchemes?.dark?.palette,
                    /** [1] */
                    primary: getPalettePrimary(settings.primaryColor),
                },
            },
        },
        customShadows: {
            ...customShadows,
            /** [1] */
            primary:
                settings.primaryColor === 'default'
                    ? coreCustomShadows('light').primary
                    : createShadowColor(getPalettePrimary(settings.primaryColor).mainChannel),
        },
    };
}

/**
 * Update components with settings.
 * @param settings
 * @returns
 */
export function updateComponentsWithSettings(settings: SettingsState) {
    const components: ThemeComponents = {};

    /** [2] */
    if (settings.contrast === 'hight') {
        const MuiCard: Components<Theme>['MuiCard'] = {
            styleOverrides: {
                root: ({ theme, ownerState }) => {
                    let rootStyles = {};
                    if (typeof MuiCardCoreComponent?.MuiCard?.styleOverrides?.root === 'function') {
                        rootStyles =
                            MuiCardCoreComponent?.MuiCard?.styleOverrides.root({
                                ownerState,
                                theme,
                            }) ?? {};
                    }

                    return {
                        ...rootStyles,
                        boxShadow: theme.customShadows.z1,
                    };
                },
            },
        };

        components.MuiCard = MuiCard;
    }

    return { components };
}
