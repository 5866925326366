import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from '../auth/context/jwt/auth-provider';
import { MotionLazy } from '../components/animate/MotionLazy';
import { ProgressBar } from '../components/progress-bar/ProgressBar';
import { defaultSettings } from '../components/settings/config-settings';
import { SettingsProvider } from '../components/settings/context/settings-provider';
import { SettingsDrawer } from '../components/settings/drawer/SettingsDrawer';
import { SnackbarProvider } from '../components/snackbar/SnackbarProvider';
import '../global.css';
import { schemeConfig as defaultSchemeConfig } from '../theme/color-scheme-script';
import { ThemeProvider, type ThemeProviderProps } from '../theme/theme-provider';

type AppProviderProps = {
    children: React.ReactNode;
    settings?: typeof defaultSettings;
    /**
     * Custom ThemeProvider component type. Use this when implementing a custom theme for your app.
     */
    themeProvider?: React.ComponentType<ThemeProviderProps>;
};

/**
 * This component is a wrapper for all the providers that you need to use in your app.
 * Add your own routing as a child of this component.
 *
 * 1. {@link MotionLazyContainer}
 * 2. {@link ThemeProvider}
 * 3. {@link ThemeSettings}
 * 4. {@link ThemeLocalization}
 * 5. {@link SnackbarProvider}
 *
 * @param param {@link AppProviderProps}
 *
 * ## Usage
 * ```tsx
 *
 * import { AppProvider } from '@mej/ui';
 * return (
 *     <AppProvider>
 *         Hello World
 *     </AppProvider>
 * );
 * ```
 */
export function AppProvider({
    children,
    themeProvider: ThemeProviderComponent = ThemeProvider,
    settings = defaultSettings,
}: AppProviderProps) {
    const schemeConfig: typeof defaultSchemeConfig = {
        ...defaultSchemeConfig,
        defaultMode: settings.colorScheme,
    };

    return (
        <HelmetProvider>
            <AuthProvider>
                <SettingsProvider settings={settings}>
                    <ThemeProviderComponent schemeConfig={schemeConfig}>
                        <MotionLazy>
                            <ProgressBar />
                            <SettingsDrawer />
                            <SnackbarProvider>{children}</SnackbarProvider>
                        </MotionLazy>
                    </ThemeProviderComponent>
                </SettingsProvider>
            </AuthProvider>
        </HelmetProvider>
    );
}
