import { createContext, useCallback, useMemo, useState } from 'react';

import { useLocalStorage } from '../../../hooks/use-local-storage';
import { STORAGE_KEY } from '../config-settings';
import type { SettingsContextValue, SettingsProviderProps, SettingsState } from '../types';

export const SettingsContext = createContext<SettingsContextValue | undefined>(undefined);

export const SettingsConsumer = SettingsContext.Consumer;

/**
 * Settings provider.
 * @param param {@link SettingsProviderProps}
 */
export function SettingsProvider({ children, settings }: SettingsProviderProps) {
    const values = useLocalStorage<SettingsState>(STORAGE_KEY.key, settings);

    const [openDrawer, setOpenDrawer] = useState(false);

    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev);
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    }, []);

    const memoizedValue = useMemo(
        () => ({
            ...values.state,
            canReset: values.canReset,
            onReset: values.resetState,
            onUpdate: values.setState,
            onUpdateField: values.setField,
            openDrawer,
            onCloseDrawer,
            onToggleDrawer,
        }),
        [
            values.state,
            values.canReset,
            values.resetState,
            values.setState,
            values.setField,
            openDrawer,
            onCloseDrawer,
            onToggleDrawer,
        ],
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
