import { defaultFont } from '../../theme/core/typography';
import type { SettingsState } from './types';

export const STORAGE_KEY = {
    key: 'app-settings',
};

export const defaultSettings: SettingsState = {
    colorScheme: 'light',
    direction: 'ltr',
    contrast: 'hight',
    navLayout: 'vertical',
    primaryColor: 'default',
    navColor: 'integrate',
    compactLayout: true,
    fontFamily: defaultFont,
} as const;
