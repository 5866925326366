import packageJson from '../package.json';

/**
 * Configuration values for the UI application.
 */
export type ConfigValue = {
    assetsDir: string;
    site: {
        name: string;
        serverUrl: string;
        assetURL: string;
        basePath: string;
        version: string;
    };
    auth: {
        method: 'jwt';
        skip: boolean;
        redirectPath: string;
        signInPath: string;
    };
};

/**
 * Configuration values for the UI application.
 */
export const CONFIG: ConfigValue = {
    assetsDir: '/static/',
    site: {
        name: 'Sej',
        serverUrl: import.meta.env.VITE_SERVER_URL ?? '',
        assetURL: import.meta.env.VITE_ASSET_URL ?? '',
        basePath: import.meta.env.VITE_BASE_PATH ?? '',
        version: packageJson.version,
    },
    /**
     * Auth
     * @method jwt | amplify | firebase | supabase | auth0
     */
    auth: {
        method: 'jwt',
        skip: false,
        redirectPath: '',
        signInPath: 'sign-in',
    },
};
