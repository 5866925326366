import type {} from '@mui/lab/themeAugmentation';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-tree-view/themeAugmentation';

import { useSettingsContext } from '../components/settings/context/use-settings-context';
import { schemeConfig as defaultSchemeConfig } from './color-scheme-script';
import { createTheme } from './create-theme';
import { RTL } from './with-settings/right-to-left';

export type ThemeProviderProps = {
    children: React.ReactNode;
    schemeConfig?: typeof defaultSchemeConfig;
};

/**
 * Theme provider.
 * @param param {@link ThemeProviderProps}
 * @returns
 */
export function ThemeProvider({
    children,
    schemeConfig = defaultSchemeConfig,
}: ThemeProviderProps) {
    const settings = useSettingsContext();

    const theme = createTheme(settings);

    return (
        <CssVarsProvider
            theme={theme}
            defaultMode={schemeConfig.defaultMode}
            modeStorageKey={schemeConfig.modeStorageKey}
        >
            <CssBaseline />
            <RTL direction={settings.direction}>{children}</RTL>
        </CssVarsProvider>
    );
}
