/**
 * Helper function to check if local storage is available.
 * @returns True if local storage is available
 */
export function localStorageAvailable() {
    try {
        const key = '__some_random_key_you_are_not_going_to_use__';
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Helper function to set an item in local storage.
 * @param key Key to set
 * @param defaultValue Default value to set
 * @returns Value set in local storage. If local storage is not available, return undefined.
 */
export function localStorageGetItem(key: string, defaultValue = '') {
    const storageAvailable = localStorageAvailable();

    let value;

    if (storageAvailable) {
        value = localStorage.getItem(key) || defaultValue;
    }

    return value;
}
