import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, IconButton, SvgIcon } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack';
import { useRef } from 'react';

import { StyledNotistack } from './styles';

type SnackbarIconProps = {
    Icon: typeof SvgIcon;
    color: 'info' | 'success' | 'warning' | 'error';
};

/**
 * Snackbar Icon
 * @param param {@link SnackbarIconProps}
 */
function SnackbarIcon({ Icon, color }: SnackbarIconProps) {
    return (
        <Box
            component="span"
            sx={{
                mr: 1.5,
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
                color: `${color}.main`,
                bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
            }}
        >
            <Icon color={color} width={24} />
        </Box>
    );
}

type SnackbarProviderProps = {
    children: React.ReactNode;
};

/**
 * Notistack is a notification library which makes it extremely easy to display notifications on your web apps.
 * It is highly customizable and enables you to stack snackbars/toasts on top of one another
 */
export function SnackbarProvider({ children }: SnackbarProviderProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const notistackRef = useRef<any>(null);

    // eslint-disable-next-line require-jsdoc
    const onClose = (key: SnackbarKey) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <>
            <StyledNotistack />

            <NotistackProvider
                ref={notistackRef}
                dense
                maxSnack={5}
                preventDuplicate
                autoHideDuration={3000}
                variant="success" // Set default variant
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                iconVariant={{
                    info: <SnackbarIcon Icon={InfoIcon} color="info" />,
                    success: <SnackbarIcon Icon={CheckCircleIcon} color="success" />,
                    warning: <SnackbarIcon Icon={WarningIcon} color="warning" />,
                    error: <SnackbarIcon Icon={ErrorIcon} color="error" />,
                }}
                // With close as default
                // eslint-disable-next-line react/no-unstable-nested-components
                action={(key) => (
                    <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
                        <CloseIcon />
                    </IconButton>
                )}
            >
                {children}
            </NotistackProvider>
        </>
    );
}
