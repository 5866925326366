import { Box } from '@mui/material';
import NoSsr from '@mui/material/NoSsr';

import { Icon, disableCache } from '@iconify/react';
import { forwardRef } from 'react';

import { iconifyClasses } from './classes';
import type { IconifyProps } from './types';

/**
 * Iconify component
 */
export const Iconify = forwardRef<SVGElement, IconifyProps>(
    ({ className, width = 20, sx, ...other }, ref) => {
        const baseStyles = {
            width,
            height: width,
            flexShrink: 0,
            display: 'inline-flex',
        };

        const renderFallback = (
            <Box
                component="span"
                className={iconifyClasses.root.concat(className ? ` ${className}` : '')}
                sx={{ ...baseStyles, ...sx }}
            />
        );

        return (
            <NoSsr fallback={renderFallback}>
                <Box
                    ref={ref}
                    component={Icon}
                    className={iconifyClasses.root.concat(className ? ` ${className}` : '')}
                    sx={{ ...baseStyles, ...sx }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...other}
                />
            </NoSsr>
        );
    },
);

// https://iconify.design/docs/iconify-icon/disable-cache.html
disableCache('local');
