import { useCallback, useEffect, useMemo } from 'react';

import { useSetState } from '../../../hooks/use-set-state';
import type { AuthState } from '../../types';
import { AuthContext } from '../auth-context';

type AuthProviderProps = {
    children: React.ReactNode;
};

/**
 *
 * @param param {@link AuthProviderProps}
 * @returns
 */
export function AuthProvider({ children }: AuthProviderProps) {
    const { state, setState } = useSetState<AuthState>({
        user: null,
        loading: true,
    });

    const checkUserSession = useCallback(async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setState({ user: null, loading: false });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setState({ user: null, loading: false });
        }
    }, [setState]);

    useEffect(() => {
        checkUserSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user
                ? {
                      ...state.user,
                      role: state.user?.role ?? 'admin',
                  }
                : null,
            checkUserSession,
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
        }),
        [checkUserSession, state.user, status],
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
