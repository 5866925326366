import { LazyMotion, domMax } from 'framer-motion';

type MotionLazyProps = {
    children: React.ReactNode;
};

/**
 * @param param {@link MotionLazyProps}
 */
export function MotionLazy({ children }: MotionLazyProps) {
    return (
        <LazyMotion strict features={domMax}>
            {children}
        </LazyMotion>
    );
}
