import { Box } from '@mui/material';

import { forwardRef } from 'react';

import { svgColorClasses } from './classes';
import type { SvgColorProps } from './types';

export const SvgColor = forwardRef<HTMLSpanElement, SvgColorProps>(
    ({ src, className, sx, ...other }, ref) => (
        <Box
            ref={ref}
            component="span"
            className={svgColorClasses.root.concat(className ? ` ${className}` : '')}
            sx={{
                width: 24,
                height: 24,
                flexShrink: 0,
                display: 'inline-flex',
                bgcolor: 'currentColor',
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        />
    ),
);
